import { homepage, tasksTypes, taskUrgency, tasksStatuses  , taskFields, roles } from '../Global';
import { CloseTaskButton } from './ActionButtons';
import useGlobal from '../store';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button, IconButton, ToggleButtonGroup, ToggleButton, Checkbox, FormControlLabel , TextField, MenuItem, Autocomplete,
Chip, Stack, List, ListItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import ru from 'dayjs/locale/ru';
import { ruRU } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, TimeField } from '@mui/x-date-pickers';
import { PatternFormat } from "react-number-format";
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

dayjs.locale('ru');

function EditTaskModal ({ taskID =0, children }) {
const [ globalState, globalActions ] = useGlobal();
const { departments, users, usersData } = globalState;
const { refreshData } = globalActions;

const [open, setOpen] = useState(false);
const [responseState, setResponseState] = useState(0);

const [values, setValues] = useState({"type": '1', "urgency": '0', "task": null, "description": null, "departmentID": '0', "closingDate": null, "executorID": '0', "status": '0' });
const [taskType, setTaskType] =useState(null);
const [urgency, setUrgency] =useState(false);
const [departmentID, setDepartmentID] =useState('');
const [executorID, setExecutorID] =useState('');
const [freeDate, setFreeDate] =useState(false);
const [deadline, setDeadline] =useState(null);
const [observers, setObservers] =useState([]);
const [taskStatus, setTaskStatus] =useState('');
const userID =localStorage.getItem('user');
let currentUser =(usersData.length ? usersData[userID] : null);

//function
const handleClose = () => {
setOpen(false);
setObservers([]);
}

// function
const handleSubmit = (e) => {
e.preventDefault();
const formData = new FormData(e.currentTarget);
if(taskID) formData.append("id", taskID);
formData.append("type", taskType);
formData.append("deadline", (freeDate ? '' : dayjs(deadline, "DD.MM.YY HH:mm").format("YYYY-MM-DD HH:mm:ss")));
if(observers.length)
{
let aObservers =[];
observers.forEach((observer) => aObservers.push(observer.id));
formData.append("observers", aObservers);
}
(userID  ? formData.append("userID", userID) : formData.append("userID", 0));

const formJson = Object.fromEntries(formData.entries());

const action =(taskID ===0 ? 'createTask' : 'updateTask' );
fetch(homepage+'/tracker/'+action, {
method: "POST",
body: JSON.stringify(formJson)
})
.then(response => response.json())
.then(data => setResponseState(data.return))
.catch(error => alert(error));
}

// effect. Закрытие после сохранения
useEffect(() => {    
if(responseState==1)
{
handleClose();
refreshData(true);
setResponseState(0);
}
}, [responseState]);

// function
async  function getTask(){
await  fetch(homepage+'/tracker/getTask', {
method: "POST",
body: JSON.stringify({"id": taskID})
})
.then(response => response.json())
.then(data => { setValues(data); })
.catch(error => console.log(error));

return 1;
}

// function
const [permittedDepartments, setPermittedDepartments] =useState(['На выбор администратора']);
const [permittedUsers, setPermittedUsers] =useState(['На выбор администратора']);
const [optionUsers, setOptionUsers] =useState([]);
const handleClick= (e, taskID) =>
{
if(currentUser.role ==='0')
{
departments.forEach((label, id) => { permittedDepartments[id] =label });
users.forEach((label, id) => { permittedUsers[id] =label });
}
else if(currentUser.role ==='1')
{
departments.forEach((label, id) => { if(id ==currentUser.departmentID) permittedDepartments[id] =label });
usersData.forEach((user) => { if(user && user.departmentID ==currentUser.departmentID) permittedUsers[user.id] =users[user.id]});
}
else
{
departments.forEach((label, id) => { if(id ==currentUser.departmentID) permittedDepartments[id] =label });
users.forEach((label, id) => { if(id ==currentUser.id) permittedUsers[id] =label });
}
setPermittedDepartments(permittedDepartments);
setPermittedUsers(permittedUsers);
if(!taskID) setDepartmentID(currentUser.departmentID);

users.forEach((label, id) => { if(label && id >0) optionUsers.push({id: id, label: label}) });
setOptionUsers(optionUsers);

if(taskID)
{
getTask()
.then (setOpen(true));
}
else
setOpen(true);
}

// effect. Данные загружены
useEffect(() => {
if (!taskID || values.task) {
setTaskType(values.type);
setUrgency(values.urgency ==='1' ? true : false);
if(currentUser && values.departmentID !=currentUser.departmentID )
{
permittedDepartments[values.departmentID] =departments[values.departmentID]; 
setPermittedDepartments(permittedDepartments);
}
setDepartmentID(values.departmentID);
if(currentUser && usersData[values.executorID].departmentID  !=currentUser.departmentID )
{
permittedUsers[values.executorID] =users[values.executorID]; 
setPermittedUsers(permittedUsers);
}
setExecutorID(values.executorID);
setTaskStatus(values.status);
if(!values.closingDate  && values.tID)
setFreeDate(true);
else
{
const date =(values.closingDate ? dayjs(values.closingDate) : dayjs().add(1, 'day').hour(18).minute(0).second(0) );
setDeadline(date); 
}
if(values.observers)
values.observers.split(',').forEach((observerID) => observers.push({id: observerID, label: users[observerID] }) );
}
}, [values]);


return (
<>
<Button onClick={(e) => {handleClick(e, taskID) }} >{children}</Button>
<Dialog
open={open}
onClose={handleClose}
PaperProps={{
component: 'form',
onSubmit: (event) => { handleSubmit(event) }
}}
>
<DialogTitle>{taskID ==0 ? 'Добавить задачу' : 'Редактирование задачи' }</DialogTitle>
<DialogContent>
<DialogContentText>
Постановка задачи
</DialogContentText>
<Stack direction='row' spacing={3}>
<ToggleButtonGroup
id="type"
value={taskType}
exclusive
onChange={(event, newValue) => setTaskType(newValue) }
>
{tasksTypes.map((label, i) => (
<ToggleButton key={i} value={i.toString()}>{label}</ToggleButton>
)).reverse() }
</ToggleButtonGroup>
<FormControlLabel control={<Checkbox name="urgency" />} checked={urgency} label="Срочно!"           onChange={() => setUrgency(!urgency) } />
</Stack>
<TextField
id="task"
name="task"
label="Задача:"
autoFocus
required
fullWidth
multiline
defaultValue ={values.task}
/>
<TextField
id="description"
name="description"
label="Подробнее:"
fullWidth
multiline
maxRows={4}
defaultValue ={values.description}
/>
<TextField
id="departments"
name="departmentID"
select
label="Отдел:"
value ={departmentID}
onChange={(event) => setDepartmentID(event.target.value) }
>
{permittedDepartments.map((label, id) => (label ?
<MenuItem key={id} value={id}>{label}</MenuItem>
: null ))}
</TextField>
<TextField
id="users"
name="executorID"
select
label="Исполнитель:"
value ={executorID}
onChange={(event) => setExecutorID(event.target.value) }
>
{permittedUsers.map((label, id) => ( label ?
<MenuItem key={id} value={id}>{label}</MenuItem>
: null ))}
</TextField>
<Box id='deadline'>
<Checkbox  checked={freeDate} inputProps={{ 'title': 'С открытой датой' }} onChange={() => setFreeDate(!freeDate) } />
<LocalizationProvider dateAdapter={AdapterDayjs}
localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
<DatePicker disabled={freeDate}
label='Выполнить до:'
format="DD.MM.YY"
value ={deadline}
onChange={(newDay ) => setDeadline(newDay)}
/>
<TimeField disabled={freeDate}
label="Время"
ampm={false}
format="HH:mm"
value={deadline}
onChange={(newTime) => setDeadline(newTime)}
/>
</LocalizationProvider>
</Box>
{ usersData.length && usersData[userID].role !=2 ?
<Autocomplete
multiple
value={observers}
options={optionUsers}
onChange={(event, newValue) => { setObservers([...newValue]); }}
getOptionLabel={(option) => option.label}
forcePopupIcon={false}
isOptionEqualToValue={(option, value) => option.label === value.label }
renderTags={(tagValue, getTagProps) =>
tagValue.map((option, index) => (
<Chip
label={option.label}
onClick={() => setObservers (observers.filter((value, i) => i !==index) ) }
{...getTagProps({ index })}
/>
))
}
renderInput={(params) => (
<TextField {...params} label="Наблюдатели:" />
)}
/>
: null }
{taskID !==0 ?
<TextField
id="status"
name="status"
select
label="Статус:"
value ={taskStatus}
onChange={(event) => setTaskStatus(event.target.value) }
>
{tasksStatuses  .map((label, i) => (            
<MenuItem key={i} value={i.toString()}>{label}</MenuItem>
)) }
</TextField>
: null }
</DialogContent>
<DialogActions>
<Button onClick={handleClose}>Отменить</Button>
<Button type="submit">Отправить</Button>
</DialogActions>
</Dialog>
</>
);
}

function TaskInfo({ index, fromUri, children }) {
const [ globalState, globalActions ] = useGlobal();
const { departments, users, usersData, refreshState } = globalState;
const { fetchOptions, refreshData } = globalActions;
const navigate =useNavigate();
const userID =localStorage.getItem('user');
let currentUser =(usersData.length ? usersData[userID] : null);

const [open, setOpen] = useState(false);
if(fromUri)
{
if(!open)
{
fetchOptions();
fetchTaskInfo (index);
}
}

// function
const [showHistory , setShowHistory] =useState(false);
const handleClose = () => {
setOpen(false);
setShowHistory (false);
if(fromUri)
navigate ('/');
}

// function
const [info, setInfo] =useState({});
async function fetchTaskInfo (id) {
await fetch(homepage+'/tracker/getTask', {
method: "POST",
body: JSON.stringify({"id": id})
})
.then(response => response.json())
.then(data => { setInfo(data) })
.catch(error => console.log(error));

return 1;
}

// function 
const handleClickTaskInfo =(e, id) => {
fetchTaskInfo (id);
}

// effect. Обновление карточки после сохранения
useEffect (() => {
if(refreshState && open)
{
fetchTaskInfo (index);
refreshData (false);
}
},[refreshState]);

// effect. Данные загружены
const [observers, setObservers] =useState('');
let disabled =false;
useEffect (() => {
if (Object.keys(info).length !=0) {
let aObservers =[];
if(info.observers )
{
(info.observers ).split(',').forEach((id) => aObservers.push(users[id]));
setObservers(aObservers.join(', '));
}
setOpen(true ) ;
refreshData (false);
}
}, [info]);

return (
<>
<Link onClick={(e) => {handleClickTaskInfo(e, index) }} tabIndex={0}>{children}</Link>
<Dialog open={open} onClose={handleClose} fullWidth   maxWidth="xl">
<Stack direction='row' >
<DialogTitle>{children}</DialogTitle>
<IconButton autoFocus aria-label="x" onClick={handleClose}><CloseIcon /></IconButton>
</Stack >
<DialogContent>
<DialogContentText>
{fromUri ? <>{info.task}<br /></> : null }
{info.description}
</DialogContentText>
<Box>
<List component={Stack}>
<ListItem>Тип: {tasksTypes[info.type]}</ListItem>
<ListItem>{taskUrgency[info.urgency]}</ListItem>
<ListItem>Создана: {dayjs(info.openingDate).format("D MMM YY г в HH:mm")}</ListItem>
<ListItem>Дедлайн: {info.closingDate ? dayjs(info.closingDate).format("D MMM YY г в HH:mm") : 'Дата открыта' }</ListItem>
<ListItem>Статус: {tasksStatuses[info.status]}</ListItem>
<ListItem>Отдел: {departments[info.departmentID]}</ListItem>
<ListItem>Исполнитель: {users[info.executorID]}</ListItem>
<ListItem>Создал: {users[info.creatorID]}</ListItem>
{observers ? <ListItem>Наблюдатели: {observers}</ListItem> : null }
</List>
</Box>
<Box>
<Link tabIndex={0} onClick={() => setShowHistory(!showHistory) }>{showHistory ==false ? <>{info.count} Правок</> : <>Свернуть</> }</Link>
{showHistory ? <TaskHistory index={index} /> : null }
</Box>
</DialogContent>
<DialogActions>
<Button onClick={() =>handleClose()}>Ок</Button>
<CloseTaskButton disabled={ info.status ==2 ? true : false } taskID={index}>Готово</CloseTaskButton>
{ currentUser && (currentUser.role ==0 
||  (currentUser.role ==1 && (currentUser.departmentID ==info.departmentID || currentUser.id ==info.creatorID)) 
|| (currentUser.role ==2 && currentUser.id == info.creatorID)) ?
<EditTaskModal taskID={index}>Изменить</EditTaskModal >
: null }
</DialogActions>
</Dialog>
</>
);
}

function TaskHistory({index =0})
{
const [ globalState, globalActions ] = useGlobal();
const { departments, users } = globalState;
const { fetchOptions } = globalActions;

// function
const [history, setHistory] =useState([]);
async function fetchHistory ()
{
await fetch(homepage+'/tracker/historyChanges', {
method: "POST",
body: JSON.stringify({"id": index})
})
.then(response => response.json())
.then(data => setHistory(data))
.catch(error => console.log(error));

return 1;
}

// function
function formatRowData(row)
{
row.changeTime =dayjs(row.changeTime).format("D MMM YY г в HH:mm");
row.userID =users[row.userID];

switch (row.field)
{
case 'type':
row.oldValue =tasksTypes[row.oldValue];
row.newValue =tasksTypes[row.newValue];
break;
case 'urgency':
row.oldValue =(row.oldValue ==1 ? "Срочно!" : "В рабочем порядке" );
row.newValue =(row.newValue ==1 ? "Срочно!" : "В рабочем порядке");
break;
case 'departmentID':
row.oldValue =departments[row.oldValue];
row.newValue =departments[row.newValue];
break; 
case 'closingDate':
row.oldValue =(row.oldValue =='' ? "Открытая дата" : dayjs(row.oldValue ).format("D MMM YY г"));
row.newValue =(row.newValue =='' ? "Открытая дата" : dayjs(row.newValue ).format("D MMM YY г"));
break;
case 'executorID':
row.oldValue =users[row.oldValue];
row.newValue =users[row.newValue];
break;
case 'observers':
let oldObservers =[];
(row.oldValue).split(',').forEach((id) => oldObservers.push(users[id]));
row.oldValue =oldObservers.join(', ');
let newObservers =[];
(row.newValue).split(',').forEach((id) => newObservers.push(users[id]));
row.newValue =newObservers.join(', ');
break;
case 'status':
row.oldValue =tasksStatuses[row.oldValue];
row.newValue =tasksStatuses[row.newValue];
break;
}

row.field =taskFields[row.field];
return (row);
}

// Effects. Точка входа
const [fetchState, setFetchState] =useState(0);
useEffect(() =>
{
fetchOptions ()
.then(data => setFetchState(data));
fetchHistory ();
}, []);

// effect. История загружена
const [formattedData, setFormattedData] =useState([]);
useEffect(() => {
if(fetchState && history.length )
{
setFormattedData(history.map((row) => { return formatRowData(row); }));
}
}, [fetchState, history]);

return (
<>
<Table>
<TableHead>
<TableRow>
<TableCell>Дата изменения</TableCell>
<TableCell>Внёс изменения</TableCell>
<TableCell>Параметр</TableCell>
<TableCell width="30%">Было</TableCell>
<TableCell width="30%">Стало</TableCell>
</TableRow>
</TableHead>
<TableBody>
{formattedData.map((row) =>
<TableRow key={row.id} style={{ verticalAlign: 'top' }}>
<TableCell>{row.changeTime}</TableCell>
<TableCell>{row.userID}</TableCell>
<TableCell>{row.field}</TableCell>
<TableCell>{row.oldValue}</TableCell>
<TableCell>{row.newValue}</TableCell>
</TableRow>
)}
</TableBody>
</Table>
</>
);
}

function EditUserModal({ index =0, children, trigger='link' }) {
const [ globalState, globalActions ] = useGlobal();
const { departments } = globalState;
const { refreshData } = globalActions;

const [open, setOpen] = useState(false);
const [userData , setUserData] =useState({});

// function
const handleClose = () => {
setOpen(false);
}

//function
const handleClick=(e, id) => {
if(id)
fetch(homepage+'/users/getUsers', {
method: "POST",
body: JSON.stringify({"id": id})
})
.then(response => response.json())
.then(data => { setUserData(data[0]) })
.catch(error => console.log(error));
else
setUserData({id: id});

}

//function
const [responseState, setResponseState] =useState(0);
const handleSubmit = (e) => {
e.preventDefault();
const formData = new FormData(e.currentTarget);
if(index) formData.append("id", index);
const formJson = Object.fromEntries(formData.entries());

fetch(homepage+'/users/userManage', {
method: "POST",
body: JSON.stringify(formJson)
})
.then(response => response.json())
.then(data => setResponseState(data.return))
.catch(error => alert(error));
}

// effect.Закрытие после сохранения
useEffect(() => {    
if(responseState==1)
{
handleClose();
refreshData (true);
setResponseState(0);
}
}, [responseState]);

// Effect. Данные загружены
useEffect (() => {
if(Object.keys(userData).length !=0)
setOpen(true);
}, [userData]);

return (
<>
{ trigger=='button'
? <Button onClick={(e) => {handleClick(e, index) }} tabIndex={0}>{children}</Button>
: <Link onClick={(e) => {handleClick(e, index) }} tabIndex={0}>{children}</Link>
}
<Dialog open={open} onClose={handleClose}
PaperProps={{
component: 'form',
onSubmit: (event) => { handleSubmit(event) }
}}
>
<DialogTitle>{children}</DialogTitle>
<DialogContent>
<TextField
id="userName"
name="user"
label="Пользователь:"
autoFocus
required
fullWidth
defaultValue ={userData.user}
/>
<TextField
id="departments"
name="departmentID"
select
label="Отдел:"
defaultValue ={userData.departmentID}
>
{departments.map((label, id) => ( label ? 
<MenuItem key={id} value={id}>{label}</MenuItem>
: null ))}
</TextField>
<TextField
id="role"
name="role"
select
label="Роль:"
defaultValue ={userData.role}
>
{roles.map((label, i) => (            
<MenuItem key={i} value={i.toString()}>{label}</MenuItem>
)).reverse() }
</TextField>
<PatternFormat
name="phone"
label="Телефон:"
format="+7 (###) ### ####"
allowEmptyFormatting
mask="_"
data-cy="phone"
defaultValue ={userData.phone}
customInput={TextField}
/>
<TextField
id="email"
name="email"
label="Электронная почта:"
type={'email'}
defaultValue ={userData.email}
/>
</DialogContent>
<DialogActions>
<Button onClick={handleClose}>Отменить</Button>
<Button type="submit">Отправить</Button>
</DialogActions>
</Dialog>
</>
);
}


function ShowTaskInfo () 
{
const params = useParams();

return (
<TaskInfo index={params.id} fromUri={true} />
);
}

export { TaskInfo, EditTaskModal, EditUserModal, ShowTaskInfo }