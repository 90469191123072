import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from './App';
import { ShowTaskInfo } from './Components/Modals';
import UserManager from './UserManager';
import StatisticsPage from './StatisticsPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';

const theme = createTheme(ruRU);

ReactDOM.render(
<React.StrictMode>
<ThemeProvider theme={theme}>
<Router>
<Routes>
<Route exact path="/" element={<App />} />
<Route path="/users" element={<UserManager />} />
<Route path="/statistics" element={<StatisticsPage />} />
<Route path="/taskID/:id" element={<ShowTaskInfo />} />
</Routes>
</Router>
</ThemeProvider>
</React.StrictMode>
, document.getElementById('root'));
