import { homepage, tasksTypes, taskUrgency, tasksStatuses, taskFields } from '../Global';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

// function
export const prepareExcelData =(tasks) => {
let data =[];

for(let i=0; i < tasks.length; i++)
{
data[i] ={};
for(let field of Object.keys(tasks[i]))
switch(field ) {
case 'task':
case 'department':
case 'openingDate':
case 'closingDate':
case 'executor':
case 'creator':
data[i][taskFields[field]] =tasks[i][field];
break;
case 'type':
data[i][taskFields[field]] =tasksTypes[tasks[i][field]];
break;
case 'urgency':
data[i][taskFields[field]] =taskUrgency[tasks[i][field]];
break;
case 'status':
data[i][taskFields[field]] =tasksStatuses[tasks[i][field]];
break;
}
}

return (data);
}

export const ExportToExcel = ({ apiData, fileName }) => {
const fileType =
"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const exportToCSV = (apiData, fileName) => {
const ws = XLSX.utils.json_to_sheet(apiData);
const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
const data = new Blob([excelBuffer], { type: fileType });
FileSaver.saveAs(data, fileName + fileExtension);
};

return (
<button onClick={(e) => exportToCSV(apiData, fileName)}>Выгрузить в excel</button>
);
}
