import { homepage, tasksTypes, tasksStatuses, comparisonSymbols, comparisonLabels } from './Global';
import { TaskInfo, EditTaskModal } from './Components/Modals';
import { MenuButton } from './Components/MenuButton';
import { LoginForm, FiltersForm } from './Components/Forms';
import { UserName } from './Components/Informers';
import { prepareExcelData, ExportToExcel } from './Components/ExportToExcel';
import useGlobal from './store';
import { useState, useEffect, useReducer } from 'react';
import { Box, InputAdornment, Button, IconButton , Link, TextField, Toolbar, ToggleButton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import ru from 'dayjs/locale/ru';
import { visuallyHidden } from '@mui/utils';

dayjs.locale('ru');
let initialTasks =[];

const App= () =>
{
const [ globalState, globalActions ] = useGlobal();
const { departments, users, refreshState } = globalState;
const { fetchOptions, refreshData } = globalActions;

const [toggleFormState, setToggleFormState] =useState(false);
const [toggleOwnState, setToggleOwnState] =useState(false);

const [fetchCounter, setFetchCounter] =useState(0);
const [effect, setEffect] =useState(0);

// function
const [searchText, setSearchText] = useState("");
const handleSearchOnClick = () => {
fetch(homepage+'/tracker/tasksView', {
method: "POST",
body: JSON.stringify({ search: searchText } )
})
.then(response => response.json())
.then(data => setTasks(data))
.catch(error => console.log(error));
} 

//function
const [tasks, setTasks] = useState([]);
async function fetchTasks ()
{
await fetch(homepage+'/tracker/tasksView', {
method: "POST",
body: JSON.stringify({ userID: localStorage.getItem('user'), own: localStorage.getItem('own') })
}).then(response => response.json())
.then(data => setTasks(data))
.catch(error => console.log(error));

return 1;
}

// function
const [refreshFilters, setRefreshFilters] =useState(false);
const handleRefreshFilters =() => {
setRefreshFilters(true);
}

// function
const ownHandler = () => {
let own =!toggleOwnState;
setToggleOwnState(own);
localStorage.setItem('own', (own ? 1 : 0));
refreshData(true);
}

// function
const [orderBy, setOrderBy] = useState(null);
const [order, setOrder] = useState('asc');
const handleSort = (property) => {
const isAsc = orderBy === property && order === 'asc';
setOrder(isAsc ? 'desc' : 'asc');
setOrderBy(property);
}

const headCells = [
{
id: 'type',
label: 'Тип'
},
{
id: 'task',
label: 'Задача'
},
{
id: 'department',
label: 'Отдел'
},
{
id: 'openingDate',
label: 'Открыта'
},
{
id: 'closingDate',
label: 'Дедлайн'
},
{
id: 'executor',
label: 'Исполнитель'
},
{
id: 'creator',
label: 'Создал'
},
{
id: 'status',
label: 'Статус'
}
];

// function
const handleFilterBy =(filter =null, id) =>
{
let filtersList  =[];
let tempTasks =[];
let filteredTasks=[...tasks];
let filtersFromStorage =JSON.parse(localStorage.getItem('filter'));

if(filter ==null && (filtersFromStorage  ==null || !Object.keys(filtersFromStorage).length))
return;

if(filtersFromStorage  !=null)
filtersList =Object.entries(filtersFromStorage);
else 
filtersFromStorage ={};

if(filter !=null)
{
filtersFromStorage[filter] =id;
filtersList  =[[filter, id]];
}

for(let [filterKey, storageValue] of filtersList)
{
for(let row of filteredTasks )
{
switch(filterKey) {
case 'department':
case 'executor':
case 'creator':
if(row[filterKey+'ID'] ==storageValue)
tempTasks  .push(row);
break;
case 'freeDate':
if(row.closingDate ==null)
tempTasks  .push(row);
break;
case 'openingDate':
case 'deadline':
const keyString =( filterKey =='deadline' ? 'closingDate' : 'openingDate');
const [date, comparison] =storageValue;
if((comparison =='<' && dayjs(row[keyString]).isBefore(date))
|| (comparison =='>' && dayjs(row[keyString]).isAfter(date, 'day'))
|| (comparison =='=' && dayjs(row[keyString]).isSame(date, 'day')) )
tempTasks  .push(row);
break;
default:
if(row[filterKey] ==storageValue)
tempTasks  .push(row);
}}
filteredTasks =tempTasks  ;
tempTasks  =[];
}

localStorage.setItem('filter', JSON.stringify(filtersFromStorage));
setTasks(filteredTasks  );
redrawFilterBar()
}

// function
const [filters, setFilters] =useState("");
function redrawFilterBar()
{
let filterArray =[];
let filterString;
const filtersFromStorage =JSON.parse(localStorage.getItem('filter'));

if(filtersFromStorage ==null || Object.keys(filtersFromStorage).length ==0)
{
setFilters('');
return;
}

for(let filter of Object.keys(filtersFromStorage))
{
switch(filter) {
case 'type':
filterArray.push([tasksTypes [filtersFromStorage.type], filter]);
break;
case 'urgency':
filterArray.push(['Срочно!', filter]);
break;
case 'department':
filterArray.push([departments[filtersFromStorage.department], filter]);
break;
case 'freeDate':
filterArray.push(['С открытой датой', filter]);
break;
case 'openingDate':
filterArray.push(['Создана '+comparisonLabels[comparisonSymbols.indexOf(filtersFromStorage .openingDate[1])]+' '+dayjs(filtersFromStorage .openingDate[0]).format("D MMM YY г"), filter]);
break;
case 'deadline':
filterArray.push(['Дедлайн '+comparisonLabels[comparisonSymbols.indexOf(filtersFromStorage .deadline[1])]+' '+dayjs(filtersFromStorage .deadline[0]).format("D MMM YY г"), filter]);
break;
case 'executor':
filterArray.push(['Исполнитель: '+users[filtersFromStorage.executor], filter]);
break;
case 'creator':
filterArray.push(['Открыл: '+users[filtersFromStorage.creator], filter]);
break;
case 'status':
filterArray.push([tasksStatuses[filtersFromStorage.status], filter]);
break;
}
}

filterString =(<>
<span key='resetAll'><Button onClick={() => handleResetFilters() }>Сбросить фильтры</Button></span>
{ filterArray.map(([label, filter]) =>
<span key={filter}>
{label}
<Button aria-label="<" onClick={() => handleResetFilters(filter)} />
</span>)}
</>);

setFilters(filterString );
}

// function
const handleResetFilters =(filter =null) =>
{
let filtersFromStorage =JSON.parse(localStorage.getItem('filter'));
if(filter ==null)
filtersFromStorage ={};
else
delete filtersFromStorage[filter];

localStorage.setItem('filter', JSON.stringify(filtersFromStorage));

setTasks(initialTasks )
redrawFilterBar();
handleRefreshFilters ();
}

// Effects
const [fetchState1, setFetchState1] =useState(0);
const [fetchState2, setFetchState2] =useState(0);
useEffect(() =>
{
if(refreshState ===true)
{
fetchOptions()
.then(state => setFetchState1(state) );
fetchTasks ()
.then(state => setFetchState2(state));
initialTasks =[];
refreshData(false);
setFetchCounter(fetchCounter+1);
}
}, [refreshState]);

// Effects
useEffect(() =>
{
if(refreshFilters===true)
{
handleFilterBy ();
setRefreshFilters(false);
}
}, [refreshFilters]);

// effect
useEffect(() => {
if(fetchState1+fetchState2 ==2 && tasks.length !=0)
if(initialTasks.length ==0)
{
initialTasks =[...tasks];
setRefreshFilters(true);
setFetchState1(0);
setFetchState2(0);
if(localStorage.getItem('own') ==1)
setToggleOwnState(true);
}
}, [fetchState1,fetchState2]);

// effect
const [, forceUpdate] = useReducer(x => x + 1, 0);
const [exportFileName, setExportFileName] =useState('');
const [excelData, setExcelData] =useState([]);
useEffect(() => {
if(tasks.length >0)
{
setExportFileName("exportTasks "+dayjs().format("YYYY-MM-DD HH_mm"));
setExcelData(prepareExcelData(tasks));
setEffect(effect+1);
}
else
if(refreshState ==false && !searchText)
{
refreshData(true);
forceUpdate();
}
}, [tasks]);

const userId =localStorage.getItem('user');
let matchs =document.cookie.match(/PinCode=(.+?)(?:;|$)/);
let pinCode =(matchs ? matchs[1] : null );
if(!userId || !pinCode )
return (
<>
<LoginForm />
</>
);
else
return (
<>
<TableContainer component={Paper}>
<Toolbar>
<MenuButton />
<TextField
id="search"
type="search"
label="Поиск"
value={searchText}
onChange={(e) => setSearchText(e.target.value)}
onKeyPress= {(e) => { if (e.key === 'Enter') handleSearchOnClick () }}
sx={{ width: 400 }}
InputProps={{
endAdornment: (
<InputAdornment position="end">
<IconButton aria-label="Искать" onClick={handleSearchOnClick}><SearchIcon /></IconButton>
</InputAdornment>
),
}}
/>
<EditTaskModal>Добавить задачу</EditTaskModal>
<Button onClick={() =>refreshData(true)}>Обновить</Button>
<ToggleButton key='toggleFormButton'  selected={toggleFormState} value={toggleFormState} onChange={() => setToggleFormState(!toggleFormState) }>Фильтры</ToggleButton>
<ToggleButton key='toggleOwnButton'  selected={toggleOwnState} value={toggleOwnState} onChange={() => ownHandler() }>Только мои</ToggleButton>
<UserName />
</Toolbar>
<Box>{toggleFormState? <FiltersForm refreshFunction={handleRefreshFilters} /> : null }</Box>
<Box id='filterBar'>{filters}</Box>
<Box id='taskCounter' component='h4' sx={{ pl: "140px" }} >Отображено {tasks.length} записей</Box>
<Table>
<TableHead>
<TableRow>
{headCells.map((headCell) => (
<TableCell key={headCell.id}>
<TableSortLabel href='/'
active={orderBy === headCell.id}
direction={orderBy === headCell.id ? order : 'asc'}
onClick={() => handleSort(headCell.id) }
>
{headCell.label}
{orderBy === headCell.id ? (
<Box component="span" sx={visuallyHidden}>
{order === 'desc' ? ' Отсортировано по убыванию' : ' Отсортировано по возрастанию'}
</Box>
) : null}
</TableSortLabel>
</TableCell>
))}
</TableRow>
</TableHead>
<TableBody>
{tasks.sort((a, b) => {
if (order === 'asc') {
return a[orderBy] < b[orderBy] ? -1 : 1;
} else {
return a[orderBy] > b[orderBy] ? -1 : 1;
}
}).map((row) => (
<TableRow key={row.id}>
<TableCell><span onClick={() => handleFilterBy('type', row.type)}>{tasksTypes[row.type]}{row.urgency ==='1' ? '!' : null }</span></TableCell>
<TableCell><TaskInfo index={row.id}>{row.task}</TaskInfo></TableCell>
<TableCell><span onClick={() => handleFilterBy('department', row.departmentID)}>{row.department}</span></TableCell>
<TableCell>{dayjs(row.openingDate).format("D MMM YY г")}</TableCell>
<TableCell>{row.closingDate ? dayjs(row.closingDate).format("D MMM YY г") : null }</TableCell>
<TableCell><span onClick={() => handleFilterBy('executor', row.executorID)}>{row.executor}</span></TableCell>
<TableCell><span onClick={() => handleFilterBy('creator', row.creatorID)}>{row.creator}</span></TableCell>
<TableCell><span onClick={() => handleFilterBy('status', row.status)}>{tasksStatuses[row.status]}</span></TableCell>
</TableRow>
))}
</TableBody>
</Table>
<Box><ExportToExcel apiData={excelData} fileName={exportFileName} /></Box>
</TableContainer>
<Box component="div" sx={visuallyHidden}>
Загрузок {fetchCounter} Эффектов {effect}
</Box>
</>
);
}

export default App;
