import {homepage, tasksTypes, tasksStatuses, comparisonSymbols, comparisonLabels } from '../Global';
import useGlobal from '../store';
import { useState, useEffect } from 'react';
import { Button, IconButton, ToggleButton, Box, FormLabel, FormControlLabel, Checkbox, Autocomplete, TextField, MenuItem, Stack } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateField } from '@mui/x-date-pickers';
import dayjs from "dayjs";
import ru from 'dayjs/locale/ru';
import { ruRU } from '@mui/x-date-pickers/locales';

function LoginForm ()
{
const [ globalState ] = useGlobal();
const { users } = globalState;

const userID =localStorage.getItem('user');
const [pinCode, setPinCode] =useState('');
const [error, setError] =useState(0);

// function
const [userInfo, setUserInfo] =useState({});
async function fetchUserInfo(email, userID)
{
await fetch(homepage+'/users/getUserInfo', {
method: "POST",
body: JSON.stringify({ email: email, userID: userID })
}).then(response => response.json())
.then(data => setUserInfo(data))
.catch(error => console.log(error));

return 1;
}

// function
const [responseState, setResponseState] =useState(0);
async function enteringPin(pinCode)
{
setResponseState(0);
await fetch(homepage+'/users/enteringPin', {
method: "POST",
body: JSON.stringify({ userID: userID, pin: pinCode})
}).then(response => response.json() )
.then(data => setResponseState(data.return) )
.catch(error => console.log(error));

return 1;
}

// functionm
const handleOnSubmit =(e) => {
e.preventDefault();

if(pinStatus ==0)
{
const email= e.target.elements.email.value;
fetchUserInfo(email);
}
else if(pinStatus >=1)
{
enteringPin(pinCode);
}
}

// effect
const [pinStatus , setPinStatus ] =useState(0);
useEffect(() => {
if(userID )
{
fetchUserInfo('', userID);
}
}, [])

// effect
useEffect(() => {

if(userInfo.id)
{
localStorage.setItem('user', userInfo.id);
(!userInfo.pin ? setPinStatus (1) : setPinStatus (2) ); 
}
if(userInfo.emailError)
{
setError(1);
}
}, [userInfo]);

// effect
useEffect(() => {
if(responseState ==1)
{
document.cookie = `PinCode=1;max-age=36000`;
window.location.reload();
}
if(responseState ==2)
{
setError(2);
}
}, [responseState]);

return (
<Box display="flex" justifyContent="center" alignItems="center">
<Box width={400} sx = {{ border: 'solid' }} >
<center><h3>Авторизация на трекере</h3></center>
{error ? 
(error ==1 ? <div>Указанный e-mail Не зарегистрирован в системе!</div> : <div>ПИН-код указан не верно!</div> )
:
<form onSubmit={handleOnSubmit}>
{ pinStatus <1 ? <>
<FormLabel>Укажите Ваш e-mail</FormLabel>
<TextField required name='email' type={'email'} />
</> : <>
<FormLabel>Приветствуем Вас, {users[userID]}</FormLabel><br /> 
</> }
{ pinStatus ? <>
{ pinStatus ==1 ? <FormLabel>Придумайте четырёхзначный ПИН-код</FormLabel> : <FormLabel>Введите ПИН:</FormLabel> }
<TextField required
name='pin'
type='number'
value ={pinCode}
onChange={(event) => {if(event.target.value.length <=4) setPinCode(event.target.value); }}
inputProps={{ min: 0, max: 9999 }}
/> </>
: null }
<br />    <Button type='submit' >Далее</Button>
</form>
}
</Box>
</Box>
);
}

function FiltersForm({ refreshFunction =() => {} }) {
const any=`Любой`;

const [ globalState, globalActions ] = useGlobal();
const { departments, users } = globalState;
let optionDepartments =[...departments];
optionDepartments[0] =any;
let optionUsers=[...users];
optionUsers[0] =any;
const types =[any].concat([...tasksTypes].reverse());
const statuses=[any].concat(tasksStatuses);

let filtersFromStorage =JSON.parse(localStorage.getItem('filter'));
if(filtersFromStorage  ==null)
filtersFromStorage ={};

const [type, setType] =useState(filtersFromStorage.type !=null ? 2- parseInt(filtersFromStorage.type) : 0);
const [urgency, setUrgency] =useState(filtersFromStorage.urgency !=null ? true : false);
const [department, setDepartment] =useState(filtersFromStorage.department !=null ? filtersFromStorage.department : 0);
const [openComparisonID, setOpenComparisonID] =useState(filtersFromStorage.openingDate!=null ? comparisonSymbols.indexOf(filtersFromStorage.openingDate[1]) : 0);
const [openingDate, setOpeningDate] =useState(filtersFromStorage.openingDate !=null ? dayjs(filtersFromStorage.openingDate[0])  : null);
const [freeDate, setFreeDate] =useState(filtersFromStorage.freeDate!=null ? true : false);
const [deadlineComparisonID, setDeadlineComparisonID] =useState(filtersFromStorage.deadline !=null ? comparisonSymbols.indexOf(filtersFromStorage.deadline[1]) : 0);
const [deadline, setDeadline] =useState(filtersFromStorage.deadline !=null ? dayjs(filtersFromStorage.deadline[0])  : null);
const [executor, setExecutor] =useState(filtersFromStorage.executor !=null ? filtersFromStorage.executor : 0);
const [creator, setCreator] =useState(filtersFromStorage.creator !=null ? filtersFromStorage.creator : 0);
const [status, setStatus] =useState(filtersFromStorage.status !=null ? parseInt(filtersFromStorage.status)+1 : 0);

// function
const handleSubmit =(e) =>
{
e.preventDefault();

if(type >0)
filtersFromStorage .type =type%2;
if(urgency)
filtersFromStorage .urgency=1;
if(department >0)
filtersFromStorage.department =department;
if(openingDate !=null)
filtersFromStorage.openingDate=[dayjs(openingDate, "DD.MM.YY").format("YYYY-MM-DD"), comparisonSymbols [openComparisonID]];
if(freeDate)
filtersFromStorage.freeDate =1;
if(deadline !=null)
filtersFromStorage.deadline =[dayjs(deadline, "DD.MM.YY").format("YYYY-MM-DD"), comparisonSymbols [deadlineComparisonID]];
if(executor>0)
filtersFromStorage.executor =executor;
if(creator >0)
filtersFromStorage.creator= creator;
if(status  >0)
filtersFromStorage.status =status-1;

localStorage.setItem('filter', JSON.stringify(filtersFromStorage));
refreshFunction();
}

// function
const handleResetForm =() =>
{
setType(0);
setUrgency(false);
setDepartment(0);
setOpenComparisonID(0);
setOpeningDate(null);
setFreeDate(false);
setDeadlineComparisonID(0);
setDeadline(null);
setExecutor(0);
setCreator(0);
setStatus(0);
}

return (
<Box component='form' onSubmit={(event) => handleSubmit(event) }>
<Stack direction='row' alignItems='center' spacing={1}>
<FormControlLabel control={<Button aria-label='Тип' />} label={types[type]}
onClick={(e) => { if(e.target.localName =="button") setType((type+1)%3) } }/>
<Checkbox checked={urgency} inputProps={{ 'title': 'Срочно!' }} onChange={() => setUrgency(!urgency) } />
<Autocomplete
options={optionDepartments.map((label, id) => { return({id:id, label:label}) } )}
value={department ? optionDepartments[department] : null }
style={{ width: 300 }}
clearOnEscape
forcePopupIcon={false}
isOptionEqualToValue={(option, value) => option.value === value.value}
onChange={(event, item) => setDepartment(item.id) }
renderInput={(params) => (
<TextField {...params} label="Отдел"  fullWidth />
)}
/>
<LocalizationProvider dateAdapter={AdapterDayjs}
localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
<span>Создана
<FormControlLabel control={<Button value={openComparisonID} aria-label=' ' />} label={comparisonLabels[openComparisonID]}
onClick={(e) => { if(e.target.localName =="button") setOpenComparisonID((openComparisonID+1)%3) } }/>
<DateField value={openingDate} format="DD.MM.YY" label=' ' onChange={(newDay ) => setOpeningDate(newDay)} />
</span>
<Checkbox  checked={freeDate} inputProps={{ 'title': 'С открытой датой' }} onChange={() => setFreeDate(!freeDate) } />
<span>Дедлайн
<FormControlLabel control={<Button disabled={freeDate} value={deadlineComparisonID} aria-label=' ' />} label={comparisonLabels[deadlineComparisonID]}
onClick={(e) => { if(e.target.localName =="button") setDeadlineComparisonID((deadlineComparisonID+1)%3) } }/>
<DateField disabled={freeDate} value={deadline} format="DD.MM.YY" label=' ' onChange={(newDay ) => setDeadline(newDay)} />
</span>
</LocalizationProvider>
<Autocomplete
options={optionUsers.map((label, id) => { return({id: id, label: label}) })}
value={executor ? optionUsers[executor] : null }
style={{ width: 300 }}
clearOnEscape
forcePopupIcon={false}
isOptionEqualToValue={(option, value) => option.value === value.value}
onChange={(event, item) => setExecutor(item.id) }
renderInput={(params) => (
<TextField {...params} label="Исполнитель" fullWidth />
)}
/>
<Autocomplete
options={optionUsers.map((label, id) => { return({id:id, label:label}) } )}
value={creator ? optionUsers[creator] : null }
style={{ width: 300 }}
clearOnEscape
forcePopupIcon={false}
isOptionEqualToValue={(option, value) => option.value === value.value}
onChange={(event, item) => setCreator(item.id) }
renderInput={(params) => (
<TextField {...params} label="Создал" fullWidth />
)}
/>
<TextField
select
label="Статус"
value={status}
onChange={(event) => setStatus(event.target.value) }
>
{statuses  .map((label, i) => (            
<MenuItem key={i} value={i}>{label}</MenuItem>
)) }
</TextField>

<Button type='submit' aria-label='Применить' size='large' />
<IconButton aria-label="Очистить форму" onClick={handleResetForm} size='large'><ClearIcon /></IconButton>
</Stack>
</Box>
);
}

export  { LoginForm, FiltersForm }