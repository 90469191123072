import { homepage } from '../Global';
import useGlobal from '../store';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function BackButton()
{
const navigate = useNavigate();
return (
<IconButton aria-label="Назад" onClick={() => navigate('/') }><ArrowBackIcon /></IconButton>
);
}

function CloseTaskButton({taskID, disabled, children })
{
const [ globalState, globalActions ] = useGlobal();
const { refreshData } = globalActions;

// function
const [responseState, setResponseState] =useState(0);
const closeTaskHandler  =() => {
fetch(homepage+'/tracker/closeTask', {
method: "POST",
body: JSON.stringify({"id": taskID})
})
.then(response => response.json())
.then(data => setResponseState(data.return))
.catch(error => console.log(error));
}

// effect. обновление по готовности
useEffect(() => {    
if(responseState==1)
{
refreshData(true);
setResponseState(0);
}
}, [responseState]);

return (
<Button disabled={disabled} onClick={() => closeTaskHandler() }>{children}</Button>
);
}

export { BackButton, CloseTaskButton }