import { homepage } from '../Global';

export async function fetchOptions( store) {
let fetchData={};
const userID =localStorage.getItem('user');

await fetch(homepage+'/tracker/getOptions')
.then(response => response.json())
.then(data => fetchData =data )
.catch(error => console.log(error));

store.setState({
users: Array.from(fetchData.users),
departments: Array.from(fetchData.departments),
usersData: Array.from(fetchData.usersData),
});

return (1);
}

export function refreshData (store, state) 
{
store.setState({
refreshState: state
});
}
