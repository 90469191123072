import { homepage } from './Global';
import useGlobal from './store';
import { useState, useEffect } from 'react';
import { prepareExcelData, ExportToExcel } from './Components/ExportToExcel';

export default function StatisticsPage ()
{
  const fileName = "myfile"; // here enter filename for your excel file

//function
const [tasks, setTasks] = useState([]);
async function fetchTasks ()
{
await fetch(homepage+'/db/tasksView', {
method: "POST",
body: JSON.stringify({ userID: localStorage.getItem('user') })
}).then(response => response.json())
.then(data => setTasks(data))
.catch(error => console.log(error));

return 1;
}

useEffect(() => {
fetchTasks  ();
  }, [])

// effect
const [excelData, setExcelData] =useState([]);
useEffect(() => {
if(tasks.length)
{
setExcelData(prepareExcelData(tasks));
}

}, [tasks]);

return (
<>
      <ExportToExcel apiData={excelData} fileName={fileName} />
</>
);
}
