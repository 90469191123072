const homepage=(window.location.hostname =="localhost" ? 'http://openserver' : 'https://tt.audiologos.ru/api');
const tasksTypes  =['Проблема', 'Задача']; 
const taskUrgency =['', 'Срочно!'];
const tasksStatuses  =['Открыта', 'На уточнении', 'Готово', 'Закрыта'];
const comparisonSymbols=['<', '>', '='];
const comparisonLabels =['до', 'после', 'точно'];
const roles =['Администратор', 'Руководитель', 'Сотрудник'];
const taskFields ={type: 'Тип', urgency: 'Срочность', task: 'Задача', description: 'Описание', departmentID: 'Отдел', department: 'Отдел', openingDate: 'Открыта', closingDate: 'Дедлайн',
executorID: 'Исполнитель', executor: 'Исполнитель', creator: 'Создал', observers: 'Наблюдатели', status: 'Статус' };
export { homepage, tasksTypes, taskUrgency, tasksStatuses, comparisonSymbols, comparisonLabels, roles, taskFields }
