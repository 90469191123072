import { Dropdown, Menu, MenuItem } from '@mui/base';
import { MenuButton as SimpleMenuButton} from '@mui/base/MenuButton';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

function MenuButton() {
const navigate = useNavigate();

// function
const logout =() => {
localStorage.removeItem('user');
document.cookie = `PinCode=;max-age=1`;
window.location.reload();
}

return (
<Dropdown>
<SimpleMenuButton aria-label='*' />
<Menu slots={{ listbox: Listbox }}>
<MenuItem onClick={() => navigate('/')}>Трекер</MenuItem>
<MenuItem onClick={() => navigate('/statistics')}>Статистика</MenuItem>
<MenuItem onClick={() => navigate('/manuals')}>Справочники</MenuItem>
<MenuItem onClick={() => navigate('/users')}>Пользователи</MenuItem>
<MenuItem onClick={() => logout() }>Выход</MenuItem>
</Menu>
</Dropdown>
);
}

const Listbox = styled('ul')(
({ theme }) => `
box-sizing: border-box;
padding: 6px;
margin: 12px 0;
min-width: 200px;
border-radius: 12px;
overflow: auto;
outline: 0px;
background: ${ '#C2E0FF' };
border: 1px solid ${ '#DAE2ED' };
box-shadow: 0px 4px 6px ${ 'rgba(0,0,0, 0.05)' };
`,
);

export  { MenuButton }
