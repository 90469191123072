import useGlobal from '../store';

function UserName()
{
const [ globalState, globalActions ] = useGlobal();
const { users } = globalState;

const userId =localStorage.getItem('user');
const userName =users[userId];

return  (
<span>{userName}</span>
);
}

export   { UserName }