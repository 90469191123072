import GlobalHook from "use-global-hook";
import * as actions from "./actions.js";

const initialState = {
departments:[],
users: [],
usersData: [],
refreshState: true
};

const useGlobal = GlobalHook( initialState, actions);

export default useGlobal;
