import { homepage, roles } from './Global';
import { EditUserModal } from './Components/Modals';
import { BackButton } from './Components/ActionButtons';
import useGlobal from './store';
import { useState, useEffect } from 'react';
import { Box, InputAdornment, Button, IconButton , Link, TextField, Toolbar, ToggleButton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';

export default function UserManager ()
{
const [ globalState, globalActions ] = useGlobal();
const { usersData, departments, refreshState } = globalState;
const { fetchOptions, refreshData } = globalActions;

//function
const [userList, setUserList] = useState([]);
async function fetchUserList()
{
await fetch(homepage+'/users/getUsers')
.then(response => response.json())
.then(data => setUserList(data))
.catch(error => console.log(error));

return 1;
}

// function
const [orderBy, setOrderBy] = useState(null);
const [order, setOrder] = useState('asc');
const handleSort = (property) => {
const isAsc = orderBy === property && order === 'asc';
setOrder(isAsc ? 'desc' : 'asc');
setOrderBy(property);
}

// initialization
const headCells = [
{
id: 'user',
label: 'Пользователь'
},
{
id: 'department',
label: 'Отдел'
},
{
id: 'role',
label: 'Роль'
}
];

// Effects
const [fetchState, setFetchState] =useState(0);
useEffect(() =>
{
fetchOptions ();
fetchUserList()
.then(state => setFetchState(state));
refreshData(false);
}, []);

useEffect(() =>
{
if(refreshState)
{
fetchUserList()
.then(state => setFetchState(state));
refreshData(false);
}
}, [refreshState]);

const userID =localStorage.getItem('user');
let currentUser =(usersData.length ? usersData[userID] : null);

return (
<>
{ currentUser && currentUser.role ==0 ?
<TableContainer component={Paper}>
<Toolbar>
<BackButton />
<EditUserModal trigger='button'>Добавить пользователя</EditUserModal> 
</Toolbar>
<Table>
<TableHead>
<TableRow>
{headCells.map((headCell) => (
<TableCell key={headCell.id}>
<TableSortLabel href='/'
active={orderBy === headCell.id}
direction={orderBy === headCell.id ? order : 'asc'}
onClick={() => handleSort(headCell.id) }
>
{headCell.label}
{orderBy === headCell.id ? (
<Box component="span" sx={visuallyHidden}>
{order === 'desc' ? ' Отсортировано по убыванию' : ' Отсортировано по возрастанию'}
</Box>
) : null}
</TableSortLabel>
</TableCell>
))}
</TableRow>
</TableHead>
<TableBody>
{userList.sort((a, b) => {
if (order === 'asc') {
return a[orderBy] < b[orderBy] ? -1 : 1;
} else {
return a[orderBy] > b[orderBy] ? -1 : 1;
}
}).map((row) => (
<TableRow key={row.id}>
<TableCell><EditUserModal index={row.id}>{row.user}</EditUserModal></TableCell>
<TableCell>{row.department}</TableCell>
<TableCell>{roles[row.role]}</TableCell>
</TableRow>
))}
</TableBody>
</Table>
</TableContainer>
: <Box>Необходима авторизация с правами администратора!</Box> }
</>
);
}
